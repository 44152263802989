import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, MenuItem, Stack, TableCell, TextField, Typography, Dialog, useTheme, useMediaQuery, Checkbox, RadioGroup, Radio, InputAdornment, FormControlLabel, Divider } from '@mui/material';
import { Circle, Close, Delete, Edit, InfoOutlined, Search, SwapHoriz, WarningTwoTone} from '@mui/icons-material';
import moment from 'moment';
import { Context } from '../App';
import { API } from '../asset/conf';
import SorTableCheckable from './sortable-checkable';
import ReservationForm from './reservation-form';
import pdfMake from "pdfmake/build/pdfmake";
import {list_passagers, list_embarques, list_equipage} from "../asset/pdfmaker";
import pdfFonts from "pdfmake/build/vfs_fonts";
import BilletForm from './billet-form';
import TecScan from './tec-scan';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function Embarquement() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {updateConnect} = useContext(Context)
    const user = JSON.parse(sessionStorage.getItem('agent'))
    const [ports, setPorts] = useState([])
    const [day_rotations, setDaysRtn] = useState([])
    const [rotationId, setRotationId] = useState('')

    const [date, setDate] = useState(moment().format('yyyy-MM-DD'))
    const [open, setOpen] = useState(false)
    const [action, setAction] = useState('')
    const [loading, setLoad] = useState(true)
    const [billets, setBillets] = useState([])
    const [billet, setBillet] = useState()
    const [pdf, setPdf] = useState()
    const [pay_date, setPaydate] = useState(moment().format('yyyy-MM-DD'))
    const [pay_mode, setPaymode] = useState('')
    const [pay_ref, setPayref] = useState('')
    
    const [selected, setSelect] = useState([])
    const [search_input, setSearch] = useState('')
    const [filter, setFilter] = useState('')
    const [scanned, setScanned] = useState('')

    const [captain, setCaptain] = useState('')
    const [meca, setMeca] = useState([])
    const [equipage, setEquipage] = useState([])
    const [personel, setPersonel] = useState([])

    useEffect(()=>{
        setLoad(true)
        fetch(API.concat(`/rotations/${JSON.stringify({date_depart: new Date(date), canceled: false, is_clotured: false})}/ships_ports`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rtn, ships, ports} = await response.json()
                    Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                        item.from = ports.find(p=> p._id===item?.from)
                        item.to = ports.find(p=> p._id===item?.to)
                        item.bateau = ships.find(sh=> sh._id===item?.bateau)
                        resolve(item)
                    })})).then(rotations=>{
                        setLoad(false)
                        setDaysRtn(rotations)
                    })
                }else setLoad(false); setDaysRtn([])
            }
        })
    },[date, updateConnect])

    const rotation = useMemo(()=>day_rotations?.find(rt=> rt._id===rotationId),[rotationId, day_rotations])

    const table =[
        {header:'N° Billet', sortable: true, sortBy:(el)=>el.num, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.num}</TableCell>},
        {header:'Type', sortable: true, sortBy:(el)=>el.reservation?.where??'Agence', row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'100px'}}>{row.reservation?.where?? 'Agence'}</TableCell>},
        {header:'Date départ', sortable: true, sortBy:(el)=>new Date(el.rotation?.date_depart).getTime(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'90px'}}>{moment.utc(row.rotation?.date_depart).format('DD/MM/yyyy')}</TableCell>},
        {header:'Passager', sortable: true, sortBy:(el)=>(el.passager.nom+' '+el.passager.prenom).toLowerCase(), row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.passager.nom+' '+row.passager.prenom}</TableCell>},
        {header:'Status paiement', sortable: true, sortBy:(el)=>el.reservation?.pay_status, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'80px', textTransform:'capitalize'}}>{row.reservation?.pay_status==='payé'||row.isValide?'payé':<Button variant='contained' onClick={()=>{setBillet(row); manage_dialog(true, 'pay')}}>reste à payer {((row.reservation?.rest_to_pay??0)+row.reservation?.extra_to_pay??0).toFixed(2)}€</Button>}</TableCell>},
        {header:'Embarquer', sortable: true, sortBy:(el)=>el.isScanned, row:(row, index)=> <TableCell key={index} align='center' sx={{minWidth:'180px'}}><Button variant='contained' color={row.isScanned?'secondary':'primary'} disabled={row.isScanned? false: Boolean(!((row.isValide??true)||row.reservation?.rest_to_pay<=0))} onClick={()=>{setBillet(row); manage_dialog(true, 'valid')}}>{row.isScanned?'annuler':'embarqué'}</Button></TableCell>},
        {header:isMobile?'Actions':'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'120px'}} style={{display:'flex', borderWidth:0, alignItems:'center', justifyContent:'end'}}>
            {row.reservation?.pay_status!=="remboursée"&&!isMobile&&<IconButton aria-label="modifier" onClick={()=>{setBillet(row); manage_dialog(true, 'edit')}}><Edit fontSize="small"/></IconButton>}
            {["en attente"].includes(row.reservation?.pay_status)&&<IconButton aria-label="supprimer" onClick={()=>{setBillet(row); manage_dialog(true, 'delete')}}><Delete fontSize="small"/></IconButton>}
            <IconButton aria-label="informations complémentaires" onClick={()=>{setBillet(row); get_info(row)}}><InfoOutlined fontSize="small"/></IconButton>
            {(row.reservation?.pay_status==='payé'||row.isValide)?<Circle color='vert' fontSize='small' sx={{marginX:'7px'}}/>
            :<>{row.reservation?.pay_status==='en attente'&&<IconButton aria-label="non payé" onClick={()=>{setBillet(row); manage_dialog(true, 'pay status')}}><WarningTwoTone color='yellow' fontSize='small'/></IconButton>}
            {row.reservation?.pay_status==='Acompte versé'&&<IconButton aria-label="acompte" onClick={()=>{setBillet(row); manage_dialog(true, 'pay status')}}><Circle color='primary' fontSize='small'/></IconButton>}
            </>}
            {row.rotation.waiting_list.includes(row._id)&&<IconButton onClick={()=>{setBillet(row); manage_dialog(true, 'bascule')}}><SwapHoriz fontSize='small' sx={{marginX:'7px'}}/></IconButton>}
        </TableCell>},
    ]

    async function manage_dialog(state, action) {
        setOpen(state)
        setAction(action)
        if (!state) {
            setBillet()
        }
    }

    async function get_info(row) {
        console.log(row); setLoad(true)
        fetch(API.concat(`/reservations/info/${JSON.stringify({passagers: row.reservation.passagers, manager: row.reservation.by})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {psg, mng} = await response.json()
                    row.reservation.passagers = psg.map(psg=>psg.nom+' '+psg.prenom)
                    row.reservation.by = mng.nom+' '+mng.prenom+' ('+mng.poste+')'
                    setBillet(row)
                    manage_dialog(true, 'info')
                    setLoad(false)
                }
            }
        }) 
    }

    async function get_embarques() {
        setLoad(true)
        const pdfDocGenerator = pdfMake.createPdf(list_embarques(
            billets.filter(blt=>blt.isScanned).sort((a,b)=>(a.passager.nom+' '+a.passager.prenom).toLowerCase()<(b.passager.nom+' '+b.passager.prenom).toLowerCase()?-1:1), date));
        pdfDocGenerator.getBlob((blob) => {
            setPdf(URL.createObjectURL(blob))
            manage_dialog(true, 'pdf')
            setLoad(false)
        })
    }

    function get_equipage() {
        setLoad(true)
        if (personel.length>0) {
            const rtn = {...rotation, captain:personel.find(p=>p._id===rotation.captain), meca:personel.filter(p=>rotation.meca.includes(p._id)), equipage:personel.filter(p=>rotation.equipage.includes(p._id))}
            const pdfDocGenerator = pdfMake.createPdf(list_equipage(rtn));
            pdfDocGenerator.getBlob((blob) => {
                setPdf(URL.createObjectURL(blob))
                manage_dialog(true, 'pdf')
                setLoad(false)
            })
        } else {
            fetch(API.concat(`/equipage`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        const rtn = {...rotation, captain:json.find(p=>p._id===rotation.captain), meca:json.filter(p=>rotation.meca.includes(p._id)), equipage:json.filter(p=>rotation.equipage.includes(p._id))}
                        const pdfDocGenerator = pdfMake.createPdf(list_equipage(rtn));
                        pdfDocGenerator.getBlob((blob) => {
                            setPdf(URL.createObjectURL(blob))
                            manage_dialog(true, 'pdf')
                            setLoad(false)
                        })
                    } else setLoad(false)
                }
            })
        }
    }

    function getEquipage(actionValue) {
        setLoad(true); 
        fetch(API.concat(`/equipage`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    setPersonel(json)
                    setCaptain(rotation.captain??'')
                    setMeca(rotation.meca??[])
                    setEquipage(rotation.equipage??[])
                    manage_dialog(true, actionValue)
                } setLoad(false)
            }
        })
    }

    async function sendEquipage() {
        setLoad(true); manage_dialog(false, '')
        fetch(API.concat(`/rotations/${rotation._id}/equipage`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        },body: JSON.stringify({captain, meca, equipage})
        }).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    const newRtn = {...rotation, equipage:json?.equipage, captain:json?.captain, meca:json?.meca}
                    setDaysRtn(old=>[...old.filter(rt=>rt._id!==json._id),newRtn])
                    if(action==='equipage&cloture')manage_dialog(true,'cloture')
                } setLoad(false)
            }
        })
    }

    async function handlePay(e) {
        e.preventDefault()
        setLoad(true)
        fetch(API.concat(`/reservations/${billet.reservation._id}/pay_rest`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }, body: JSON.stringify({pay_date, pay_mode, pay_ref, montant: billet.reservation?.rest_to_pay+billet.reservation?.extra_to_pay, by: user?.where??user.nom})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json()
                    setBillets(old=>old.map(bl=>{
                        if (bl.reservation._id.toString()===json._id.toString()) {
                            bl.reservation = json
                            bl.isValide = true
                        } return bl
                    }))
                    manage_dialog(false, '')
                    setPaydate(moment().format('yyyy-MM-DD')); setPaymode(''); setPayref('')
                } setLoad(false)
            }
        })
    }

    async function get_pdf() {
        setLoad(true)
        const pdfDocGenerator = pdfMake.createPdf(list_passagers(
            billets.filter(blt=>filtrer(blt)).sort((a,b)=>(a.passager.nom+' '+a.passager.prenom).toLowerCase()<(b.passager.nom+' '+b.passager.prenom).toLowerCase()?-1:1), 
            date, filter));
        pdfDocGenerator.getBlob((blob) => {
            setPdf(URL.createObjectURL(blob))
            manage_dialog(true, 'pdf')
            setLoad(false)
        })
    }

    async function handleSwap() {
        setLoad(true)
        fetch(API.concat(`/reservations/${billet.reservation._id}/rotation/${billet.rotation._id}/bascule`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    manage_dialog(false, '')
                }else setLoad(false)
            }
        })
    }

    async function handleDelete() {
        setLoad(true)
        fetch(API.concat(`/reservations/nopayed/${JSON.stringify([billet.reservation._id])}`), {method:'DELETE', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    setBillets(old=>old.filter(blt=>blt.reservation._id!==billet.reservation._id))
                    manage_dialog(false, '')
                }
            }
            setLoad(false)
        })
    }

    function get_billets() {
        fetch(API.concat(`/rotations/billets/${JSON.stringify({rotation: rotationId})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, blt, psg} = await response.json()
                    Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                        item.passager = psg.find(p=> p._id===item.passager)
                        item.rotation = rotation
                        item.reservation = rsv.find(r=> r.billets.includes(item._id))
                        resolve(item)
                    })})).then(results=>{
                        setBillets(results.filter(bl=> bl.reservation?.pay_status!=='remboursée'));
                        setLoad(false)
                    })
                }else setLoad(false)
            }
        })
    }

    function handleScan() {
        setLoad(true)
        fetch(API.concat(`/billets/${billet._id}/update`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({isScanned: !billet.isScanned})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json() 
                    billet.isScanned = json.isScanned
                    manage_dialog(true, 'confirm_valid')
                }
                setLoad(false)
            }
        })
    }

    function handleTransition() {
        setLoad(true)
        fetch(API.concat(`/billets/${JSON.stringify({_id: {$in: selected}})}`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }, body: JSON.stringify({$unset: {rotation: ''}})
        }).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) { 
                    setBillets(old=> old.filter(blt=> !selected.includes(blt._id)))
                    setSelect([])
                    manage_dialog(false, '')
                }
                setLoad(false)
            }
        })
    }

    function normalize(str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
    }

    function get_searchable(blt) {
        return blt.num+' '+blt.reservation.num+' '+blt.reservation.num_facture+' '+blt.passager.nom+' '+blt.passager.prenom
    }

    function filtrer(blt) {
        if(filter!=='') {
            switch (filter) {
                case 'payé':
                    return !["en attente", "remboursée"].includes(blt.reservation.pay_status)
                case 'transition':
                    return blt.rotation.waiting_list.includes(blt._id)
                default:
                    return ["en attente", "remboursée"].includes(blt.reservation.pay_status);
            }
        } else return true
    }

    async function handleCloture() {
        setLoad(true)
        fetch(API.concat(`/cloture_embarquement/${JSON.stringify([rotationId])}`), {method:'PATCH', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                } if (response.status===200) {
                    manage_dialog(true, 'confirm_cloture')
                } setLoad(false)
            }
        })
    }

    useEffect(()=>{
        function get_ports() {return new Promise((resolve, reject) => {
            fetch(API.concat('/ports'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async(response)=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setPorts(json)
                        resolve(json)
                    }
                }
            })  
        })}

        get_ports()
        if (rotationId!=='') {
            get_billets()            
        }
    }, [updateConnect, rotationId])

    return (isMobile?
        <Box sx={{
            display: 'flex',
            flex: 1,
            marginBottom: 3,
            flexDirection: 'column',
            paddingInline: isMobile ? '10px' : '30px'
          }}>
            <Typography 
              variant={'h4'} 
              textAlign="center" 
              sx={{marginBottom: 3, paddingBlock:2, backgroundColor:'#EE96BA'}}
            >
              Outil d'embarquement
            </Typography>
      
            {/* Section de contrôles - réorganisée pour mobile */}
            <Box sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              gap: isMobile ? 2 : 0
            }}>
              <Stack 
                direction={isMobile ? "column" : "row"} 
                spacing={1} 
                sx={{
                  alignItems: isMobile ? 'stretch' : 'center',
                  width: isMobile ? '100%' : 'auto'
                }}
              >
                <Typography variant='subtitle1'>Date:</Typography>
                <TextField
                  type='date'
                  value={date}
                  onChange={(e)=>{setDate(e.target.value); setRotationId(''); setBillet(); setBillets([])}}
                  size='small'
                  fullWidth={isMobile}
                />
                <TextField 
                  select 
                  label='rotation' 
                  InputLabelProps={{style:{marginRight:'5px'}}} 
                  value={rotationId}
                  onChange={(e)=>{setRotationId(e.target.value)}} 
                  size='small' 
                  sx={{
                    minWidth: isMobile ? '100%' : '250px'
                  }}
                >
                  {day_rotations?.length>0 ? 
                    day_rotations
                      .sort((a,b)=>Number(a.heure_depart.join(''))-Number(b.heure_depart.join('')))
                      .map((item)=>
                        <MenuItem key={item._id} value={item._id} style={{textTransform:'capitalize'}}>
                          {moment(item.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} {item.from.pays.toUpperCase()} {'->'} {item.to.pays.toUpperCase()} - {item.bateau.nom}
                        </MenuItem>
                      ) : 
                    <Typography sx={{paddingInline:2}}>Aucune rotation pour cette date</Typography>
                  }
                </TextField>
              </Stack>
      
              {/* Boutons d'action réorganisés pour mobile */}
              <Stack 
                direction={isMobile ? "column" : "row"} 
                spacing={1} 
                sx={{
                  alignItems: 'stretch',
                  marginLeft: isMobile ? 0 : 2,
                  marginTop: isMobile ? 2 : 0
                }}
              >
                <Button variant='contained' onClick={()=>{manage_dialog(true, 'transition'); setSelect(old=>old.length>0?
                  old.filter(id=>{const blt = billets.find(blt=>blt._id===id); return blt?.isValide&&!blt?.isScanned})
                  :billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)&&blt.isValide&&!blt.isScanned).map(blt=>blt._id))}}>
                  Transition
                </Button>
                {/* {billets.filter(blt=>filtrer(blt)).length>0 && 
                  <Button variant='contained' onClick={()=>{get_pdf()}}>Exporter</Button>
                }
                <Button 
                  variant='contained' 
                  disabled={billets.length<=0} 
                  onClick={get_embarques}
                >
                  liste Embarqués
                </Button>
                <Button 
                  variant='contained' 
                  disabled={rotation?.equipage.length<=0} 
                  onClick={get_equipage}
                >
                  liste Équipage
                </Button> */}
              </Stack>
            </Box>
      
            {/* Barre de recherche */}
            <TextField 
              size="small" 
              label="Rechercher un billet par ID ou nom" 
              disabled={!billets?.length>0}
              sx={{
                width: isMobile ? '100%' : '60%',
                marginTop: '20px',
                alignSelf: 'center'
              }} 
              value={search_input}
              InputProps={{
                endAdornment: (<InputAdornment position="end"><Search /></InputAdornment>)
              }}
              onChange={(event)=> {setSearch(event.target.value); setScanned('')}}
            />
      
            {/* Section d'info de rotation */}
            {rotation && <Box 
              display='flex' 
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent='space-between' 
              sx={{
                marginTop: '25px',
                gap: isMobile ? 2 : 0
              }}
            >
              <Stack 
                direction={isMobile ? 'column' : 'row'} 
                spacing={isMobile ? 1 : 10}
              >
                <Typography variant="subtitle1" color='primary'>
                  Passagers embarqués: {billets.filter(blt=>blt.isScanned).length}
                </Typography>
                <Typography variant="subtitle1" color='red'>
                  Places restantes: {rotation.places-billets.filter(blt=>blt.isScanned&&!blt.passager.categorie.toLowerCase().includes('nourrisson')).length}
                </Typography>
              </Stack>
              <Stack 
                direction={isMobile ? 'column' : 'row'} 
                spacing={1}
                sx={{
                  width: isMobile ? '100%' : 'auto'
                }}
              >
                <Button variant='contained' onClick={()=>manage_dialog(true,'scan')} fullWidth={isMobile}>
                  TEC Scan
                </Button>
                <Button variant='contained' onClick={()=>getEquipage('equipage')} fullWidth={isMobile}>
                  Équipage
                </Button>
                <Button variant='contained' onClick={()=>getEquipage('equipage&cloture')} fullWidth={isMobile}>
                  Clôture
                </Button>
              </Stack>
            </Box>}
      
            {/* Table des billets */}
            {billets.length>0 && (search_input.length>0 || scanned!=='') ? 
              <SorTableCheckable 
                data={billets.filter(blt=> scanned!==''? blt._id===scanned : normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt))} 
                table={table} 
                selectItems={selected} 
                setItems={setSelect} 
                rowsPerPageOptions={[25, 50,100,300]}
                rowsPerPage={100}
              />
              : rotation?.canceled && 
                <Typography 
                  fontSize={isMobile ? 24 : 32} 
                  fontWeight={500} 
                  color='red.main' 
                  align='center' 
                  marginTop='80px'
                >
                  Annulé
                </Typography>
            }

            {billets.filter(blt=>blt.isScanned).length>0&&<Typography marginTop={2} variant='subtitle1'>Liste passagers embarqués:</Typography>}
            {billets.filter(blt=>blt.isScanned).sort((a,b)=>(a.passager.nom+' '+a.passager.prenom).toLowerCase()<(b.passager.nom+' '+b.passager.prenom).toLowerCase()?-1:1).map(billet=>
                <Typography fontSize={16} textTransform='capitalize'>- {billet.passager.nom+' '+billet.passager.prenom}</Typography>
            )}
      
            {/* Les Dialogs restent identiques car ils sont déjà responsives avec fullScreen et fullWidth */}
            <Dialog 
              open={open} 
              onClose={()=>manage_dialog(false, '')} 
              fullScreen={action==='add'||(action!=='edit'&&action.includes('edit'))} 
              fullWidth 
              maxWidth={['delete', 'edit', 'valid', 'confirm_valid', 'confirm_cloture'].includes(action) ? 'xs' : action==='pdf'? 'md': 'sm'} 
              sx={{minWidth: isMobile ? '100%' : '500px'}}
            >
              {action!=='pdf'&&<IconButton onClick={()=> manage_dialog(false, '')} sx={{position:'absolute', right:'10px', top:'10px'}} ><Close/></IconButton>}
                
                {action==='valid'&& <Box display='flex' flexDirection='column' margin='30px 20px 20px 20px'>
                    <Typography variant='h5' align='center' marginBlock={2}>{billet.isScanned ? 'Annuler' : 'Confirmer'} l'embarquement de <span style={{textTransform:'capitalize'}}>{billet.passager.nom+' '+billet.passager.prenom}</span>?</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' onClick={handleScan}>Oui</Button>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Non</Button>
                    </Stack>
                </Box>}
                {action==='confirm_valid'&& <Box display='flex' flexDirection='column' margin='30px 20px 20px 20px'>
                    <Typography variant='h3' align='center' marginBlock={2}>Le passager <span style={{textTransform:'capitalize'}}>{billet.passager.nom+' '+billet.passager.prenom}</span> a embarqué</Typography>
                    <Button variant='contained' sx={{alignSelf:'center'}} onClick={()=>manage_dialog(false, '')}>Ok</Button>
                </Box>}
                {action==='scan'&&<TecScan setScanned={id=>{setScanned(id); manage_dialog(false,'')}} close={()=> manage_dialog(false, '')}/>}
                {['equipage', 'equipage&cloture'].includes(action)&&<Box display='flex' flexDirection='column' padding={3}>
                    <Typography variant='h3' align='center'>Sélection de l'équipage</Typography>
                    <Typography align='center'>Veuillez sélectionner les membres de l'équipage qui effectuent la traversée selon leur poste</Typography>
                    <Typography variant='h3' fontWeight='bold'>Capitaine:</Typography>
                    <RadioGroup value={captain} row sx={{flexWrap:'wrap'}} onChange={(e)=>setCaptain(e.target.value)}>
                        {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} value={item._id} control={<Radio />}/>)}
                    </RadioGroup>
                    <Typography variant='h3' fontWeight='bold'>Mécaniciens:</Typography>
                    {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} control={<Checkbox checked={meca.includes(item._id)} onChange={()=>setMeca(old=>old.includes(item._id)?old.filter(it=>it!==item._id):[...old, item._id])}/>}/>)}
                    <Typography variant='h3' fontWeight='bold'>Equipage:</Typography>
                    {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} control={<Checkbox checked={equipage.includes(item._id)} onChange={()=>setEquipage(old=>old.includes(item._id)?old.filter(it=>it!==item._id):[...old, item._id])}/>}/>)}
                    <Stack direction='row' spacing={2} alignItems='center' alignSelf='end' margin={1}>
                        <Button variant='contained' onClick={()=>manage_dialog(false, '')} color='secondary'>annuler</Button>
                        <Button variant='contained' disabled={captain===''} onClick={sendEquipage}>enregistrer</Button>
                    </Stack>
                </Box>}
                {action==='cloture'&&<Box display='flex' flexDirection='column' margin='20px' >
                    <Typography variant='h5' align='center' marginBlock={2}>Confirmer la clôture de l'embarquement {rotation.from.pays.toUpperCase()+' -> '+rotation.to.pays.toUpperCase()} de {moment(rotation.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} et envoyer la liste des passagers embarqués et de l'équipage à la douane?</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' onClick={handleCloture}>Confirmer</Button>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>annuler</Button>
                    </Stack>
                </Box>}
                {action==='confirm_cloture'&&<Box display='flex' flexDirection='column' margin='20px'>
                    <Typography variant='h4' align='center' marginTop='25px'> La rotation de
                        {moment(rotation.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} dans le sens {rotation.from.pays.toUpperCase()} {'->'} {rotation.to.pays.toUpperCase()} a bien été clôturée
                    </Typography>
                    <Button variant='contained' sx={{alignSelf:'center', marginTop:2}} onClick={()=>{setDaysRtn(old=> old.filter(rt=>rt._id!==rotationId)); setBillets([]); setRotationId(''); manage_dialog(false, '');}}>ok</Button>
                </Box>}
                {action==='pay'&&<Box component='form' onSubmit={handlePay} display='flex' flexDirection='column' paddingBlock='30px' paddingInline='20px'>
                    <TextField select sx={{minWidth:'300px', alignSelf:'center'}} required label="Mode de paiement" value={pay_mode} onChange={(e)=>setPaymode(e.target.value)}>
                        <MenuItem key={"especes"} value={"especes"}>
                            <Typography>Espèces</Typography> 
                        </MenuItem>
                        <MenuItem key={"cheque"} value={"cheque"}>
                            <Typography>Chèque</Typography> 
                        </MenuItem>
                        <MenuItem key={"virement"} value={"virement"}>
                            <Typography>Virement</Typography> 
                        </MenuItem>
                        <MenuItem key={"cb"} value={"cb"}>
                            <Typography>Carte Bancaire</Typography> 
                        </MenuItem>
                    </TextField>

                    <Typography sx={{marginY: '10px'}}>Détails*:</Typography>
                    <Stack direction='column' spacing={1}>
                        <Stack direction='row' spacing={1}>
                            <TextField fullWidth type="date" value={pay_date} onChange={e=>setPaydate(e.target.value)} sx={{minWidth:'150px'}}/>
                            {pay_mode!=="especes"&&<TextField fullWidth label={pay_mode==='cheque'?"N° chèque" :'Référence'} sx={{minWidth:'200px'}} value={pay_ref} onChange={e=>setPayref(e.target.value)}/>}
                        </Stack>
                        <Stack direction='column' alignItems={'end'}>
                            <Typography variant='subtitle1'>Montant: {((billet.reservation?.rest_to_pay??0)+billet.reservation?.extra_to_pay??0).toFixed(2)}€</Typography>
                            <Typography>- Reste à payer sur réservation: {(billet.reservation?.rest_to_pay??0).toFixed(2)}€</Typography>
                            <Typography>- Modification: {(billet.reservation?.extra_to_pay??0).toFixed(2)}€</Typography>
                        </Stack>
                        <Button variant='contained' type='submit' sx={{alignSelf:'center'}}>enregistrer le paiement</Button>
                    </Stack>
                </Box>}
                {action==='info' && billet && <Box display='flex' flexDirection='column' marginTop='20px' padding='20px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails billet</Typography>
                    <Box display='flex' justifyContent='space-between'>
                        <Box>
                            <Typography variant='subtitle1'>N° billet: {billet.num}</Typography>
                            <Typography variant='subtitle1'>Date départ: {moment.utc(billet.rotation.date_depart).format('DD/MM/yyyy')}</Typography>
                            <Typography variant='subtitle1' textTransform='uppercase'>Départ: {billet.rotation.from.nom}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='subtitle1' textTransform='capitalize'>Passager: {billet.passager.nom+' '+billet.passager.prenom}</Typography>
                            <Typography variant='subtitle1'>Heure départ: {billet.rotation.heure_depart.map(n=>String(n).padStart(2,'0')).join('h')}</Typography>
                            <Typography variant='subtitle1' textTransform='uppercase'>Arrivée: {billet.rotation.to.nom}</Typography>
                        </Box>
                    </Box>
                    <Divider variant='middle' sx={{marginY:'20px', marginX:'15%'}}/>

                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails réservation</Typography>
                    <Typography variant='subtitle1'>N° réservation: {billet.reservation.num}</Typography>
                    <Typography variant='subtitle1'>Type de réservation: {billet.reservation.isA_R?'Aller/Retour': 'Aller Simple'}</Typography>
                    <Typography variant='subtitle1' textTransform='capitalize'>Passagers: {billet.reservation.passagers.join(', ')}</Typography>
                    <Typography variant='subtitle1'>Statut paiement: {billet.reservation.pay_status}</Typography>
                    {billet.reservation.promo_code!==''&&<Typography variant='subtitle1'>Code promo: {billet.reservation.promo_code}</Typography>}
                    <Typography variant="subtitle1">Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€</Typography>
                    {billet.reservation.payments.length>0&&<Typography variant='subtitle1'>Paiements:</Typography>}
                    {billet.reservation.payments.map((pay, index)=>
                        <Typography key={index} variant='subtitle1' marginLeft='10px'>- {pay.montant.toFixed(2)}€ en {pay.mode} le {moment.utc(pay.date).format('DD/MM/yyyy')} {pay.ref!==''?`référencé ${pay.ref}`:''}</Typography>
                    )}
                    <Typography variant="subtitle1">Reste à payer: {billet.reservation?.rest_to_pay.toFixed(2)}€</Typography>
                    {billet.reservation?.updates>1&&<Typography variant="subtitle1">Frais de modification: {((billet?.reservation?.updates-1)*5).toFixed(2)}€</Typography>}
                    {billet.reservation?.rest_to_pay<=0&&<Typography variant="subtitle1">N° facture: {billet?.reservation?.num_facture}</Typography>}
                    {billet.reservation?.commentaire!==''&&<Typography variant="subtitle1">Commentaire: {billet?.reservation?.commentaire}</Typography>}
                    {billet.reservation?.where!=='web'&&<Typography variant="subtitle1">Réservation enregistrée par: <span style={{textTransform:'capitalize'}}>{billet?.reservation?.by}</span></Typography>}
                    </Box>}
                {action==='delete' && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Supprimer la réservation n°{billet.reservation.num}</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleDelete}>Supprimer la réservation</Button>
                    </Stack>
                </Box>}
                {action==='pdf' && pdf && <iframe
                src={`${pdf}#toolbar=0`}
                title="PDF Viewer"
                width="100%"
                height="500px"
                style={{ border: 'none' }}/>}
                {action==='transition' &&<Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Valider la transition de {selected.length} billet(s)</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleTransition}>Valider</Button>
                    </Stack>
                </Box>}
                {action==='bascule' && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Basculer la réservation {billet.reservation.num} de {billet.reservation.passagers.length} passager(s)</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleSwap}>Basculer</Button>
                    </Stack>
                </Box>}
                {action==='pay status' && billet && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Paiement</Typography>
                    <Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                        Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€
                    </Typography>
                    <Typography variant="subtitle1">Déjà payé : {billet.reservation?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
                    {billet.reservation?.extra_to_pay>0&&<Typography variant="subtitle1" style={{margin:'0 20px 20px 0'}}>
                        Frais de modification: {billet?.reservation?.extra_to_pay?.toFixed(2)}€
                    </Typography>}
                    <Typography variant="subtitle1" color='red'>Reste à payer: {(billet.reservation?.rest_to_pay+billet?.reservation?.extra_to_pay).toFixed(2)}€</Typography>
                </Box>}
            </Dialog>
      
            <Backdrop open={loading}><CircularProgress/></Backdrop>          
        </Box>
        :<Box sx={{display:'flex', flex:1, marginBottom:30, flexDirection:'column', paddingInline:'30px'}}>
            <Typography variant='h1' textAlign="center" sx={{marginBottom:7}}>Outil d'embarquement</Typography>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Stack direction="row" spacing={1} sx={{alignItems:'center'}}>
                    <Typography variant='subtitle1'>Date:</Typography>
                    <TextField
                    type='date'
                    value={date}
                    onChange={(e)=>{setDate(e.target.value); setRotationId(''); setBillet(); setBillets([])}}
                    size='small'/>
                    <TextField select label='rotation' InputLabelProps={{style:{marginRight:'5px'}}} value={rotationId}
                    onChange={(e)=>{setRotationId(e.target.value)}} size='small' sx={{minWidth:'250px'}}>
                        {day_rotations?.length>0 ? day_rotations.sort((a,b)=>Number(a.heure_depart.join(''))-Number(b.heure_depart.join(''))).map((item)=>
                            <MenuItem key={item._id} value={item._id} style={{textTransform:'capitalize'}}>
                                {moment(item.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} {item.from.pays.toUpperCase()} {'->'} {item.to.pays.toUpperCase()} - {item.bateau.nom}
                            </MenuItem>
                        ) : <Typography sx={{paddingInline:2}}>Aucune rotation pour cette date</Typography>}
                    </TextField>
                </Stack>

                <Stack direction="row" spacing={1} flexWrap='wrap' sx={{alignItems:'center', marginLeft:2}}>
                    <Button variant='contained' onClick={()=>{manage_dialog(true, 'add')}}>Ajouter</Button>
                    <Button variant='contained' onClick={()=>{manage_dialog(true, 'transition'); setSelect(old=>old.length>0?
                    old.filter(id=>{const blt = billets.find(blt=>blt._id===id); return blt?.isValide&&!blt?.isScanned})
                    :billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)&&blt.isValide&&!blt.isScanned).map(blt=>blt._id))}}>Transition</Button>
                    {billets.filter(blt=>filtrer(blt)).length>0&&<Button variant='contained' onClick={()=>{get_pdf()}}>Exporter</Button>}
                        {/* {filter==='non payé'&&billets.filter(blt=> normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt)).length>0&&<MenuItem onClick={()=>{manage_dialog(true, 'delete many'); handleClose()}}>Supprimer</MenuItem>} */}
                    <Button variant='contained' disabled={billets.length<=0} onClick={get_embarques}>liste Embarqués</Button>
                    <Button variant='contained' disabled={rotation?.equipage.length<=0} onClick={get_equipage}>liste Équipage</Button>
                </Stack>
            </Box>

            <TextField size="small" label="Rechercher un billet par ID ou nom" disabled={!billets?.length>0}
            sx={{width:'60%', minWidth:'300px', marginTop:'20px', alignSelf:'center'}} value={search_input}
            InputProps={{endAdornment: (<InputAdornment position="end"><Search /></InputAdornment>)}}
            onChange={(event)=> {setSearch(event.target.value); setScanned('')}}
            />

            {rotation&&<Box display='flex' justifyContent='space-between' sx={{marginTop:'25px'}}>
                <Stack direction='row' spacing={10}>
                    <Typography variant="subtitle1" color='primary'>Passagers embarqués: {billets.filter(blt=>blt.isScanned).length}</Typography>
                    <Typography variant="subtitle1" color='red'>Places restantes: {rotation.places-billets.filter(blt=>blt.isScanned&&!blt.passager.categorie.toLowerCase().includes('nourrisson')).length}</Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                    <Button variant='contained' onClick={()=>manage_dialog(true,'scan')}>TEC Scan</Button>
                    <Button variant='contained' onClick={()=>getEquipage('equipage')}>Équipage</Button>
                    <Button variant='contained' onClick={()=>getEquipage('equipage&cloture')}>Clôture</Button>
                </Stack>
            </Box>}

            {billets.length>0&&(search_input.length>0||scanned!=='')?<SorTableCheckable data={billets.filter(blt=> scanned!==''? blt._id===scanned : normalize(get_searchable(blt)).includes(normalize(search_input))&&filtrer(blt))} table={table} selectItems={selected} setItems={setSelect} rowsPerPageOptions={[50,100,300]} rowsPerPage={100} />
            :rotation?.canceled&&<Typography fontSize={32} fontWeight={500} color='red.main' align='center' marginTop='80px'>Annulé</Typography>}
            
            <Dialog open={open} onClose={()=>manage_dialog(false, '')} fullScreen={action==='add'||(action!=='edit'&&action.includes('edit'))} fullWidth 
            maxWidth={['delete', 'edit', 'valid', 'confirm_valid', 'confirm_cloture'].includes(action) ? 'xs' : action==='pdf'? 'md': 'sm'} sx={{minWidth:'500px'}}>
                {action!=='pdf'&&<IconButton onClick={()=> manage_dialog(false, '')} sx={{position:'absolute', right:'10px', top:'10px'}} ><Close/></IconButton>}
                {action==='add' && <ReservationForm addRsv={blt=>setBillets(old=> old.concat(blt.filter(b=>moment.utc(b.rotation?.date_depart).format('yyyy-MM-DD')===date&&b.rotation?.heure_depart.join('')===rotation.heure_depart.join(''))))} date={date} port={rotation?.from} ports={ports} close={()=> manage_dialog(false, '')}/>}
                {/* change rotation billet */}
                {action==='edit' && billet && <Box display='flex' flexDirection='column' marginY='30px'>
                    <Typography variant='h5' align='center' marginBottom='20px'>Modifier:</Typography>
                    <Stack direction='row' spacing={1} sx={{alignSelf:'center'}}>
                        {(!["en attente", "remboursée"].includes(billet.reservation?.pay_status))&&!billet.isScanned&&<Button variant='outlined' onClick={()=>setAction('edit billet')}>Le billet</Button>}    
                        <Button variant='contained' onClick={()=>setAction('edit reservation')}>La réservation</Button>
                    </Stack>    
                </Box>}
                {action==='edit billet' && <BilletForm close={()=> manage_dialog(false, '')} addRsv={edited=> setBillets(old=> { 
                    if (date===moment.utc(edited.rotation.date_depart).format('yyyy-MM-DD')) {
                        old[old.findIndex(b=> b._id===edited._id)]=edited
                        return old;
                    } else return old.filter(blt=>blt._id!==edited._id)})} 
                billet={billet} />}
                {action==='edit reservation' && <ReservationForm close={()=> manage_dialog(false, '')} 
                    addRsv={edited=> setBillets(old=> old.filter(bl=>!edited.some(ed=>ed._id===bl._id)).concat(edited.filter(b=>moment.utc(b.rotation.date_depart).format('yyyy-MM-DD')===date)))} 
                    edit={true} billet={billet} 
                    passagers={billets.filter(blt=>blt.reservation._id===billet.reservation._id).map(blt=>blt.passager)} />}
                {action==='valid'&& <Box display='flex' flexDirection='column' margin='30px 20px 20px 20px'>
                    <Typography variant='h5' align='center' marginBlock={2}>{billet.isScanned ? 'Annuler' : 'Confirmer'} l'embarquement de <span style={{textTransform:'capitalize'}}>{billet.passager.nom+' '+billet.passager.prenom}</span>?</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' onClick={handleScan}>Oui</Button>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Non</Button>
                    </Stack>
                </Box>}
                {action==='confirm_valid'&& <Box display='flex' flexDirection='column' margin='30px 20px 20px 20px'>
                    <Typography variant='h3' align='center' marginBlock={2}>Le passager <span style={{textTransform:'capitalize'}}>{billet.passager.nom+' '+billet.passager.prenom}</span> a embarqué</Typography>
                    <Button variant='contained' sx={{alignSelf:'center'}} onClick={()=>manage_dialog(false, '')}>Ok</Button>
                </Box>}
                {action==='scan'&&<TecScan setScanned={id=>{setScanned(id); manage_dialog(false,'')}} close={()=> manage_dialog(false, '')}/>}
                {['equipage', 'equipage&cloture'].includes(action)&&<Box display='flex' flexDirection='column' padding={3}>
                    <Typography variant='h3' align='center'>Sélection de l'équipage</Typography>
                    <Typography align='center'>Veuillez sélectionner les membres de l'équipage qui effectuent la traversée selon leur poste</Typography>
                    <Typography variant='h3' fontWeight='bold'>Capitaine:</Typography>
                    <RadioGroup value={captain} row sx={{flexWrap:'wrap'}} onChange={(e)=>setCaptain(e.target.value)}>
                        {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} value={item._id} control={<Radio />}/>)}
                    </RadioGroup>
                    <Typography variant='h3' fontWeight='bold'>Mécaniciens:</Typography>
                    {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} control={<Checkbox checked={meca.includes(item._id)} onChange={()=>setMeca(old=>old.includes(item._id)?old.filter(it=>it!==item._id):[...old, item._id])}/>}/>)}
                    <Typography variant='h3' fontWeight='bold'>Equipage:</Typography>
                    {personel.map(item=><FormControlLabel key={item._id} label={item.nom+' '+item.prenom} sx={{textTransform:'capitalize'}} control={<Checkbox checked={equipage.includes(item._id)} onChange={()=>setEquipage(old=>old.includes(item._id)?old.filter(it=>it!==item._id):[...old, item._id])}/>}/>)}
                    <Stack direction='row' spacing={2} alignItems='center' alignSelf='end' margin={1}>
                        <Button variant='contained' onClick={()=>manage_dialog(false, '')} color='secondary'>annuler</Button>
                        <Button variant='contained' disabled={captain===''} onClick={sendEquipage}>enregistrer</Button>
                    </Stack>
                </Box>}
                {action==='cloture'&&<Box display='flex' flexDirection='column' margin='20px' >
                    <Typography variant='h5' align='center' marginBlock={2}>Confirmer la clôture de l'embarquement {rotation.from.pays.toUpperCase()+' -> '+rotation.to.pays.toUpperCase()} de {moment(rotation.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} et envoyer la liste des passagers embarqués et de l'équipage à la douane?</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' onClick={handleCloture}>Confirmer</Button>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>annuler</Button>
                    </Stack>
                </Box>}
                {action==='confirm_cloture'&&<Box display='flex' flexDirection='column' margin='20px'>
                    <Typography variant='h4' align='center' marginTop='25px'> La rotation de
                        {moment(rotation.heure_depart.join(':'), 'HH:mm').format('H:mm').replace(':', 'h')} dans le sens {rotation.from.pays.toUpperCase()} {'->'} {rotation.to.pays.toUpperCase()} a bien été clôturée
                    </Typography>
                    <Button variant='contained' sx={{alignSelf:'center', marginTop:2}} onClick={()=>{setDaysRtn(old=> old.filter(rt=>rt._id!==rotationId)); setBillets([]); setRotationId(''); manage_dialog(false, '');}}>ok</Button>
                </Box>}
                {action==='pay'&&<Box component='form' onSubmit={handlePay} display='flex' flexDirection='column' paddingBlock='30px' paddingInline='20px'>
                    <TextField select sx={{minWidth:'300px', alignSelf:'center'}} required label="Mode de paiement" value={pay_mode} onChange={(e)=>setPaymode(e.target.value)}>
                        <MenuItem key={"especes"} value={"especes"}>
                            <Typography>Espèces</Typography> 
                        </MenuItem>
                        <MenuItem key={"cheque"} value={"cheque"}>
                            <Typography>Chèque</Typography> 
                        </MenuItem>
                        <MenuItem key={"virement"} value={"virement"}>
                            <Typography>Virement</Typography> 
                        </MenuItem>
                        <MenuItem key={"cb"} value={"cb"}>
                            <Typography>Carte Bancaire</Typography> 
                        </MenuItem>
                    </TextField>

                    <Typography sx={{marginY: '10px'}}>Détails*:</Typography>
                    <Stack direction='column' spacing={1}>
                        <Stack direction='row' spacing={1}>
                            <TextField fullWidth type="date" value={pay_date} onChange={e=>setPaydate(e.target.value)} sx={{minWidth:'150px'}}/>
                            {pay_mode!=="especes"&&<TextField fullWidth label={pay_mode==='cheque'?"N° chèque" :'Référence'} sx={{minWidth:'200px'}} value={pay_ref} onChange={e=>setPayref(e.target.value)}/>}
                        </Stack>
                        <Stack direction='column' alignItems={'end'}>
                            <Typography variant='subtitle1'>Montant: {((billet.reservation?.rest_to_pay??0)+billet.reservation?.extra_to_pay??0).toFixed(2)}€</Typography>
                            <Typography>- Reste à payer sur réservation: {(billet.reservation?.rest_to_pay??0).toFixed(2)}€</Typography>
                            <Typography>- Modification: {(billet.reservation?.extra_to_pay??0).toFixed(2)}€</Typography>
                        </Stack>
                        <Button variant='contained' type='submit' sx={{alignSelf:'center'}}>enregistrer le paiement</Button>
                    </Stack>
                </Box>}
                {action==='info' && billet && <Box display='flex' flexDirection='column' marginTop='20px' padding='20px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails billet</Typography>
                    <Box display='flex' justifyContent='space-between'>
                        <Box>
                            <Typography variant='subtitle1'>N° billet: {billet.num}</Typography>
                            <Typography variant='subtitle1'>Date départ: {moment.utc(billet.rotation.date_depart).format('DD/MM/yyyy')}</Typography>
                            <Typography variant='subtitle1' textTransform='uppercase'>Départ: {billet.rotation.from.nom}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='subtitle1' textTransform='capitalize'>Passager: {billet.passager.nom+' '+billet.passager.prenom}</Typography>
                            <Typography variant='subtitle1'>Heure départ: {billet.rotation.heure_depart.map(n=>String(n).padStart(2,'0')).join('h')}</Typography>
                            <Typography variant='subtitle1' textTransform='uppercase'>Arrivée: {billet.rotation.to.nom}</Typography>
                        </Box>
                    </Box>
                    <Divider variant='middle' sx={{marginY:'20px', marginX:'15%'}}/>

                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Détails réservation</Typography>
                    <Typography variant='subtitle1'>N° réservation: {billet.reservation.num}</Typography>
                    <Typography variant='subtitle1'>Type de réservation: {billet.reservation.isA_R?'Aller/Retour': 'Aller Simple'}</Typography>
                    <Typography variant='subtitle1' textTransform='capitalize'>Passagers: {billet.reservation.passagers.join(', ')}</Typography>
                    <Typography variant='subtitle1'>Statut paiement: {billet.reservation.pay_status}</Typography>
                    {billet.reservation.promo_code!==''&&<Typography variant='subtitle1'>Code promo: {billet.reservation.promo_code}</Typography>}
                    <Typography variant="subtitle1">Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€</Typography>
                    {billet.reservation.payments.length>0&&<Typography variant='subtitle1'>Paiements:</Typography>}
                    {billet.reservation.payments.map((pay, index)=>
                        <Typography key={index} variant='subtitle1' marginLeft='10px'>- {pay.montant.toFixed(2)}€ en {pay.mode} le {moment.utc(pay.date).format('DD/MM/yyyy')} {pay.ref!==''?`référencé ${pay.ref}`:''}</Typography>
                    )}
                    <Typography variant="subtitle1">Reste à payer: {billet.reservation?.rest_to_pay.toFixed(2)}€</Typography>
                    {billet.reservation?.updates>1&&<Typography variant="subtitle1">Frais de modification: {((billet?.reservation?.updates-1)*5).toFixed(2)}€</Typography>}
                    {billet.reservation?.rest_to_pay<=0&&<Typography variant="subtitle1">N° facture: {billet?.reservation?.num_facture}</Typography>}
                    {billet.reservation?.commentaire!==''&&<Typography variant="subtitle1">Commentaire: {billet?.reservation?.commentaire}</Typography>}
                    {billet.reservation?.where!=='web'&&<Typography variant="subtitle1">Réservation enregistrée par: <span style={{textTransform:'capitalize'}}>{billet?.reservation?.by}</span></Typography>}
                    </Box>}
                {action==='delete' && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Supprimer la réservation n°{billet.reservation.num}</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleDelete}>Supprimer la réservation</Button>
                    </Stack>
                </Box>}
                {action==='pdf' && pdf && <embed src={pdf} width='100%' height='600px' type="application/pdf" />}
                {action==='transition' &&<Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Valider la transition de {selected.length} billet(s)</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleTransition}>Valider</Button>
                    </Stack>
                </Box>}
                {action==='bascule' && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Basculer la réservation {billet.reservation.num} de {billet.reservation.passagers.length} passager(s)</Typography>
                    <Stack alignSelf='end' direction='row' spacing={1}>
                        <Button variant='contained' color='secondary' onClick={()=>manage_dialog(false, '')}>Annuler</Button>
                        <Button variant='contained' onClick={handleSwap}>Basculer</Button>
                    </Stack>
                </Box>}
                {action==='pay status' && billet && <Box display='flex' flexDirection='column' margin='30px'>
                    <Typography variant='h3' textAlign='center' marginBottom='20px'>Paiement</Typography>
                    <Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                        Prix Total de la réservation: {billet.reservation.total.toFixed(2)}€
                    </Typography>
                    <Typography variant="subtitle1">Déjà payé : {billet.reservation?.payments?.reduce((total, current)=>total+current.montant,0).toFixed(2)}€</Typography>
                    {billet.reservation?.extra_to_pay>0&&<Typography variant="subtitle1" style={{margin:'0 20px 20px 0'}}>
                        Frais de modification: {billet?.reservation?.extra_to_pay?.toFixed(2)}€
                    </Typography>}
                    <Typography variant="subtitle1" color='red'>Reste à payer: {(billet.reservation?.rest_to_pay+billet?.reservation?.extra_to_pay).toFixed(2)}€</Typography>
                </Box>}
                </Dialog>

            <Backdrop open={loading} ><CircularProgress/></Backdrop>          
        </Box>
    );
}