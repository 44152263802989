import React, {useEffect, useState} from "react";
import { Typography, MenuItem, TextField, Button, IconButton, Box, Checkbox, Stack, FormControlLabel, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import moment from "moment";

export default function PayForm(props) {
    const [open, setOpen] = useState(false)
    const [pay, setpay] = useState()

    useEffect(()=>{}, [props.payments, props.passagers])

    return(
        <Box display='flex' flexDirection='column' marginX='15px'>
            <Stack direction='column' alignItems='center' spacing={2} marginBottom='10px'>
                {props.payments.map((pay, index) => 
                <Box key={index} display='flex' flexDirection='column' justifyContent='center' width='47%' minWidth='400px' marginBottom='12px' padding='10px' border='1px solid black' borderRadius='5px' > 
                    {props.payments.length>1 && !pay.cloture&&<IconButton aria-label="supprimer" style={{alignSelf:'end'}} onClick={()=> {setpay(pay); setOpen(true)}}><Delete color="red"/></IconButton>}
                    {pay.cloture&&<Typography variant="subtitle1" color='red' align="center">Clôturé</Typography>}
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <TextField select fullWidth sx={{minWidth:'200px'}} required={pay.montant>0} label="Mode de paiement" disabled={pay.cloture}
                        value={pay.mode==='web'?'cb':pay.mode} onChange={(e)=> props.setPay(old => {const newArray = [...old]; newArray[index].mode = e.target.value; return newArray})}>
                            <MenuItem key={"especes"} value={"especes"}><Typography>Espèces</Typography></MenuItem>
                            <MenuItem key={"cheque"} value={"cheque"}><Typography>Chèque</Typography></MenuItem>
                            <MenuItem key={"virement"} value={"virement"}><Typography>Virement</Typography></MenuItem>
                            <MenuItem key={"cb"} value={"cb"}><Typography>Carte Bancaire</Typography></MenuItem>
                        </TextField>
                        {props.passagers.filter(psg=>psg!==''&&psg._id).length>0&&<TextField select fullWidth sx={{minWidth:'200px'}} label="Passager" value={pay.passager??''}
                        onChange={e=>props.setPay(old=>{old[index].passager=e.target.value;return[...old]})} disabled={pay.cloture}>
                            {props.passagers.sort((a,b)=>(a.nom+' '+a.prenom).toLowerCase()<(b.nom+' '+b.prenom).toLowerCase()?-1:1).map(psg=>
                                <MenuItem key={psg._id} value={psg._id} sx={{textTransform:'capitalize'}}>{psg.nom?.toUpperCase()} {psg.prenom}</MenuItem>
                            )}
                        </TextField>}
                    </Stack>
                
                    <Typography sx={{marginY: '10px'}}>Détails*:</Typography>
                    <Stack direction='column' spacing={1}>
                        <Stack direction='row' spacing={1}>
                            <TextField type="date" disabled={pay.cloture} value={moment.utc(pay.date).format('yyyy-MM-DD')} onChange={e=>props.setPay(old=>{const newArray = [...old]; newArray[index].date=e.target.value; return newArray})} sx={{minWidth:'150px'}}/>
                            <TextField fullWidth name="montant" label="Montant (en €)" disabled={pay.cloture} inputProps={{step:"10", min:'0'}} type="number" sx={{minWidth:'150px'}}
                            value={pay.montant} onChange={(e)=>props.setPay(old => {const newArray = [...old]; newArray[index].montant=e.target.value; return newArray})}/>
                        </Stack>
                        <Stack direction='row' spacing={1}>
                            {pay.mode!=="especes"&&<TextField disabled={pay.cloture} label={pay.mode==='cheque'?"N° chèque" :'Référence'} sx={{minWidth:'200px', width:'50%'}} value={pay.ref} onChange={e=>props.setPay(old=>{const newArray = [...old]; newArray[index].ref=e.target.value; return newArray})}/>}
                            <FormControlLabel disabled={pay.cloture} control={<Checkbox checked={pay.is_acompte} onChange={()=>props.setPay(old=>{const newArray = [...old]; newArray[index].is_acompte= !old[index].is_acompte; return newArray})} color="primary" />} label="Acompte" />
                        </Stack>
                    </Stack>
                    
                </Box>)}  
            </Stack>

            <Button aria-label="ajouter un paiement" variant="clear" sx={{alignSelf:'center', color:'primary.main', fontWeight:'bold', marginTop:'10px'}}
            onClick={()=> props.setPay(old => old.concat([{date: moment().format('yyyy-MM-DD'), montant: props.rest??0, is_acompte: false, ref: '', mode: ''}]))}>
                <Add sx={{margin:0}}/>
                <Typography>ajouter un paiment</Typography> 
            </Button>
            
            {/* <Typography variant="subtitle1">Total payé: {props.payments.reduce((tt, current)=>tt+(!isNaN(Number(current.montant))?Number(current.montant):0),0).toFixed(2)}€</Typography>  */}

            <Dialog open={open} maxWidth='xs'>
                <DialogTitle align="center">Êtes-vous sûr de vouloir supprimer le paiement {pay?.mode} de {Number(pay?.montant&&pay.montant!==''?pay?.montant:0).toFixed(2)} du {moment.utc(pay?.date).format('DD/MM/yyyy')}?</DialogTitle>
                <DialogActions>
                    <Button variant="outlined" onClick={()=>{setpay();setOpen(false)}}>non</Button>
                    <Button variant="contained" onClick={()=> {props.setPay(old => old.slice(0, old.indexOf(pay)).concat(old.slice(old.indexOf(pay)+1))); setpay(); setOpen(false)}}>Oui</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}