import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, IconButton, InputAdornment, List, ListItem, ListItemButton, MenuItem, Paper, Stack, TableCell, TextField, Typography } from '@mui/material';
import { Context } from '../App';
import { API } from '../asset/conf';
import { Add, Close, Delete, Edit, Search } from '@mui/icons-material';
import TarifForm from './tarif-form';
import PromoForm from './promo-form';
import SorTable from './sortable'
import moment from 'moment';

export default function Promo() {
  const { updateConnect } = useContext(Context)
  const [load, setLoad] = useState(true)
  const [oldest_year, setOldesty] = useState(new Date().getFullYear())
  const [year, setyear] = useState(new Date().getFullYear())
  const [tarifs, setTarifs]= useState([])
  const [promos, setPromos]= useState([])
  const [tarif, setTarif]= useState()
  const [promo, setPromo]= useState()
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState('')
  const [search, setSearch] = useState('')

  const table = [
    {header: "Code", sortable: true, sortBy:el=>el.code.toLowerCase(), 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'90px'}} align="center">{row.code}</TableCell>},
    {header: "Réduction", sortable: true, sortBy:el=>el.reduction, 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'90px'}} align="center">{row.reduction.toFixed(2)+(row.is_in_percent?' %':'€')}</TableCell>},
    {header: "Campagne", sortable: true, sortBy:el=>el.libelle.toLowerCase(), 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'150px', width:'100%'}} align="center">{row.libelle}</TableCell>},
    {header: "Date", sortable: true, sortBy:(el)=>new Date(el.start_date).getTime(), 
    row: (row, index)=><TableCell key={index} align="center" sx={{minWidth:'100px'}} >du {moment.utc(row.start_date).format('DD/MM/YYYY')}<br/>au {moment.utc(row.end_date).format('DD/MM/YYYY')}</TableCell>},
    {header: "Utilisation", sortable: true, sortBy:el=>el.use, 
    row: (row, index)=><TableCell key={index} sx={{minWidth:'90px'}} align="center">{row.use}</TableCell>},
    {header: "CA", sortable: true, sortBy:el=>el.ca, 
    row: (row, index)=><TableCell key={index} align="right" style={{fontWeight:'bold'}}>{row.ca.toFixed(2)}€</TableCell>},
    {header:'', row:(row, index)=> <TableCell key={index} align='center' sx={{maxWidth:'120px', minWidth:'90px'}}>
      <IconButton aria-label="modifier" onClick={()=>{setPromo(row); manage_dialog(true, 'edit_promo')}}><Edit fontSize="small"/></IconButton>
      <IconButton aria-label="supprimer" onClick={()=>{setPromo(row); manage_dialog(true, 'delete_promo')}}><Delete fontSize="small"/></IconButton>
    </TableCell>}
]

  async function manage_dialog(state, action) {
    setOpen(state)
    setAction(action)
    if (!state) {
        setTarif(); setPromo()
    }
  }

  function normalize(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9\s-.€]/gi,'').toLowerCase()
}

function get_searchable(promo) {
    return promo.code+' '+promo.libelle
}

function handleDelete(to_del) {
  setLoad(true)
  fetch(API.concat(`/${action.includes('promo')?'promos':'tarifs'}/${JSON.stringify({_id: to_del._id})}`), {method:'DELETE', headers:{
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    }
}).then(async(response)=>{
    const token = response.headers.get('x-auth-token')
    if (response.status === 401) {
        sessionStorage.clear()
        updateConnect(false)
    } else {
        if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
        }
        if (response.status === 200) {
            if(action.includes('promo')) setPromos(old=> old.filter(prom=> prom._id!==to_del._id))
            else setTarifs(old => old.filter(trf=> trf._id !== to_del._id))
            setLoad(false)
            manage_dialog(false,'')
        }
    }
})
}

  useEffect(()=>{
    function get_tarifs() {
      fetch(API.concat('/tarifs'), {method:'GET', headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }}).then(async response=>{
        const token = response.headers.get('x-auth-token')
        if (response.status === 401) {
          sessionStorage.clear()
          updateConnect(false)
        } else {
          if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
          }
          if (response.status === 200) {
            const json = await response.json()
            setTarifs(json)
          }
        }
      })
    }

    function get_oldest_promos() {
      fetch(API.concat(`/promos/aggregate/${JSON.stringify([{$sort:{start_date: 1}}, {$limit: 1}])}`), {method:'GET', headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }}).then(async response=>{
        const token = response.headers.get('x-auth-token')
        if (response.status === 401) {
          sessionStorage.clear()
          updateConnect(false)
        } else {
          if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
          }
          if (response.status === 200) {
            const json = await response.json()
            if(json.length>0)setOldesty(new Date(json[0].start_date).getFullYear())
          }
        }
      })
    }

    get_tarifs(); get_oldest_promos()
  }, [updateConnect])

  useEffect(()=>{
    setLoad(true)
    function get_promos() {
      let startDate = new Date(year, 0, 1); 
      let endDate = new Date(year + 1, 0, 1); 

      fetch(API.concat(`/promos/${JSON.stringify({$or: [{start_date: {$gte: startDate, $lt: endDate}}, {end_date: {$gte: startDate, $lt: endDate}}]})}`), {method:'GET', headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }}).then(async response=>{
        const token = response.headers.get('x-auth-token')
        if (response.status === 401) {
          sessionStorage.clear()
          updateConnect(false)
        } else {
          if (token && token !== sessionStorage.getItem('token') && token !== '') {
            sessionStorage.setItem('token', response.headers.get('x-auth-token'))
          }
          if (response.status === 200) {
            const json = await response.json()
            setPromos(json)
          }
          setLoad(false)
        }
      })
    }

    get_promos();
  }, [updateConnect, year])

    return (
      <Box style={{display:'flex', flex:1, marginBottom:30, flexDirection:'column'}}>
        <Typography variant='h1' align='center'>Gestion des tarifs et codes promotionnels</Typography>
        <Stack direction={'row'} spacing={5} sx={{ marginTop:'15px', paddingInline:'15px', display:'flex'}}>
          <Box sx={{display:'flex', flexDirection:'column',backgroundColor:'pink', padding:'10px', borderRadius:1, minWidth:'250px', maxWidth:'300px', justifyContent:'center'}}>
            <Typography variant='h3' align='center'>Tarifs</Typography>
            <List>
            {tarifs.map((row, index)=><Box key={index}>
              <ListItem key={row._id}>
                <ListItemButton sx={{justifyContent:'space-between'}} onClick={()=>{manage_dialog(true, 'edit_tarif'); setTarif(row)}}>
                  <Typography sx={{marginRight:'20px'}}>{row.libelle}</Typography>
                  <Typography>{row.prix.toFixed(2)}€</Typography>
                </ListItemButton>
                <IconButton aria-label="supprimer" onClick={()=>{setTarif(row); manage_dialog(true, 'delete_tarif')}}><Delete fontSize="small"/></IconButton>
              </ListItem>
              <Divider /></Box>
            )}
            </List>
            <IconButton onClick={()=>manage_dialog(true, 'add_tarif')} sx={{alignSelf:'center'}}>
              <Add/>
            </IconButton>
          </Box>

          <Box component={Paper} sx={{display:'flex', flex:1, flexDirection:'column', padding:'10px', minWidth:'700px'}}>
            <Typography variant='h3' align='center'>Codes Promotionnels</Typography>
            <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:'15px'}}>
              <TextField select size='small'
              sx={{minWidth:'120px', alignSelf:'flex-start'}} color='primary'
              required label="Année" 
              value={year} onChange={(e)=> setyear(e.target.value)}>
                  {Array.from({length: new Date().getFullYear() - oldest_year + 1}, (_, i) => oldest_year + i).map(y=>
                    <MenuItem key={y} value={y}>{y}</MenuItem>
                  )}
              </TextField>
              <Button variant='contained' startIcon={<Add/>} onClick={()=> manage_dialog(true, 'add_promo')}>Ajouter</Button>
            </Box>
            <TextField
            size="small" label="Rechercher une promo par code ou campagne" disabled={!promos?.length>0}
            sx={{width:'60%', minWidth:'300px', marginTop:'10px'}} value={search} onChange={(event)=> setSearch(event.target.value)}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
            }}
            />

            <SorTable data={promos.filter(promo=> normalize(get_searchable(promo)).includes(normalize(search)))} table={table} rowsPerPage={25} rowsPerPageOptions={[10, 25, 50, 100]}/>
          </Box>
        </Stack>

        <Dialog fullWidth maxWidth={action.includes('delete') ? 'xs' : 'sm'} open={open} sx={{minWidth:action.includes('delete') ?'250px':'500px', padding:'10px'}}>
          <IconButton onClick={()=>manage_dialog(false, '')} sx={{position:'absolute',right:'10px', top:'10px'}}><Close/></IconButton>
          {action==='add_tarif' && <TarifForm addTarif={(trf)=> setTarifs(old=> [...old, trf])} close={()=>manage_dialog(false, '')} />}
          {action==='edit_tarif' && <TarifForm edit={true} tarif={tarif} addTarif={(edited)=> setTarifs(old=> {
            const ntarif=[...old]; ntarif[ntarif.indexOf(tarif)]=edited; return ntarif
          })} close={()=>manage_dialog(false, '')} />}
          {action==='add_promo' && <PromoForm addPromo={(prom)=> setPromos(old=> [...old, prom])} close={()=>manage_dialog(false, '')} />}
          {action==='edit_promo' && <PromoForm edit={true} promo={promo} addPromo={(edited)=> setPromos(old=> {
            const npromo=[...old]; npromo[npromo.indexOf(promo)]=edited; return npromo
          })} close={()=>manage_dialog(false, '')} />}
          {(action==='delete_promo'||action==='delete_tarif')&& <>
                <DialogTitle sx={{marginInline:'30px'}}>Supprimer{action.includes('promo')?' la promo "'+promo.libelle+'"':' le tarif "'+tarif.libelle+'"'}</DialogTitle>
                <DialogActions sx={{marginBottom:'10px'}}>
                    <Button variant='contained' color="error" onClick={()=>handleDelete(action.includes('promo')?promo:tarif)}>Comfimer</Button>
                    <Button variant='contained' color='secondary' onClick={()=> manage_dialog(false,'')}>Annuler</Button>
                </DialogActions>
            </>}
        </Dialog>

        <Backdrop open={load}><CircularProgress/></Backdrop>
      </Box>
    );
}