import { Accordion, AccordionDetails, AccordionSummary, AppBar, Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Menu, MenuItem, Stack, TextField, Toolbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { API } from "../asset/conf";
import { Context } from "../App";
import moment from "moment";
import { Add, Close, ExpandMore, Remove, Search } from "@mui/icons-material";
import { theme } from "../App";
import RotatDays from "./rotat-days";
import PayForm from "./pay-mode-form";
import BilletForm from "./edit-blt-form";
import ClientSearch from "./autocomplet-search";
import ClientForm from "./client-form";


export default function EditRsvBltForm(props) {
    const { updateConnect } = useContext(Context)
    const [load, setLoad] = useState(true)
    const [dates, setDates] = useState([])
    const [dispo_rotations, setDispoRotations] = useState([])
    const [ports, setports] = useState([])
    const [tarifs, setTarifs] = useState([])
    const [promos, setpromos] = useState([])
    const [clients, setclients] = useState([])
    const [no_pmr, setPmr] = useState([])
    const [from,setfrom] =useState('')
    const [to, setTo] = useState('')

    const [reservation, setReservation] = useState({})
    const [billets, setBillets] = useState([])
    const [cat_passengers, setpassengers] = useState([{label:'Adulte (+12 ans)', count:1}, {label:'Enfant B (5-12 ans)', count:0}, {label:'Enfant A (1-4 ans)', count:0}, {label:'Nourrisson (-1 ans)', count:0}])
    const [passagers, setPassagers] = useState([])
    const [price, setPrice] = useState(0)
    const [payments, setPay] = useState([{date: moment().format('yyyy-MM-DD'), montant: 0, is_acompte: false, ref: '', mode: ''}])
    const [facture_update, setfacture] = useState(false)

    const [expanded, setExpanded] = useState('panel1');
    const [nested_expanded, setnestedExpanded] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    
    const [proofInvalid, setProofInvalid] = useState(false) 

    //useMemo pour total updated when passager and calculate if have one rotation selected
    const rotations = useMemo(()=>dispo_rotations.filter(rtn=>reservation.rotations?.includes(rtn._id)), [dispo_rotations, reservation.rotations])
    useEffect(()=>setPrice(old=>passagers.filter(psg=>psg.categorie!=='nourrisson').length>=10? old:passagers?.some(psg=>!props.billet?.reservation?.passagers.includes(psg._id))||reservation?.rotations?.some(rtn=>!props.billet?.reservation?.rotations.includes(rtn)) ? get_total():(reservation?.total ?? 0)),[passagers, props.billet?.reservation, reservation])
    const payed = useMemo(()=>reservation.payments?.reduce((tt, current)=>tt+(!isNaN(Number(current.montant))?Number(current.montant):0),0), [reservation.payments])

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        fill_passagers()
    };

    const handleChange = (panel, isExpanded, isnested) => {
        if (isnested) {
            setnestedExpanded(isExpanded ? panel : false)
        } else setExpanded(isExpanded ? panel : false);
    };

    function sendRsvDoc() {
        setLoad(true)
        fetch(API.concat(`/reservation/${reservation._id}/documents/send`), {method:'PATCH', headers:{
            // Accept: 'application/pdf',
            // 'Content-Type': 'application/pdf',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    //alert ok
                }setLoad(false)
            }
        })
    }

    function getRsvDoc() {return new Promise((resolve, reject) => {
        fetch(API.concat(`/reservation/${reservation._id}/documents`), {method:'GET', headers:{
            // Accept: 'application/pdf',
            // 'Content-Type': 'application/pdf',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async(response)=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const arrayBuffer = await response.arrayBuffer();
                    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                    resolve(blob)
                }else resolve()
            }
        })
    })}

    async function downloadRsv() {
        setLoad(true)
        const blob = await getRsvDoc()
        const downloadUrl = window.URL.createObjectURL(blob)
        // Créer un lien temporaire et cliquer dessus pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${reservation.num??'reservation'}.pdf`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Libérer l'URL de l'objet
        window.URL.revokeObjectURL(downloadUrl);
        setLoad(false)
    }

    async function printRsv() {
        setLoad(true)
        getRsvDoc().then(blob=>{
            const pdf_url = URL.createObjectURL(blob)
            const win = window.open(pdf_url, '_blank');
            if (win) {
                win.onload = function() {
                win.print();
                };
            } else {
                alert('Impossible d\'ouvrir le PDF et de l\'imprimer');
            }
            setLoad(false)  
        })
    }

    function fill_passagers() {
        if (passagers.length<=0) {
            setPassagers(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)).fill(''))
        } else if (passagers.length!==cat_passengers.reduce((count, current)=>count+current.count,0)) {
            setPassagers(old=>{
                let psgs = old.filter(psg=>psg!=='')
                if (psgs.length>cat_passengers.reduce((count, current)=>count+current.count,0)) {
                    psgs.splice(cat_passengers.reduce((count, current)=>count+current.count,0))
                    return psgs
                } else {
                    psgs = psgs.concat(new Array(cat_passengers.reduce((count, current)=>count+current.count,0)-psgs.length).fill(''))
                    return psgs
                }
            })
        }
        if (clients.length<=0) {
            setLoad(true)
            fetch(API.concat('/passagers'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        const webClients = Array.from(new Map(json.filter(cl => cl.isWeb).map(cl => [`${cl.nom.toLowerCase()}-${cl.prenom.toLowerCase()}-${cl.mail}`,{nom: cl.nom.toLowerCase(), prenom: cl.prenom.toLowerCase(), mail: cl.mail}])).values());
                        setclients([...json.filter(cl=>!cl.isWeb),
                        ...webClients.map(wcl=>json.find(cl=>cl.isWeb&&cl.nom.toLowerCase()===wcl.nom&&cl.prenom.toLowerCase()===wcl.prenom&&cl.mail===wcl.mail&&
                        !json.some(c=>c.isWeb&&c.nom.toLowerCase()===wcl.nom&&c.prenom.toLowerCase()===wcl.prenom&&c.mail===wcl.mail&&(moment.utc(c.identity_proof.exp_date).isAfter(moment.utc(cl.identity_proof.exp_date))||(c.tds_proof?.exp_date&&cl.tds_proof?.exp_date?moment.utc(c.tds_proof.exp_date).isAfter(moment.utc(cl.tds_proof.exp_date)):false)))))
                        ])
                    }
                  setLoad(false)
                }
            })
        }
        setnestedExpanded(0)
    }

    function searchRotations(date, from, to) {
        setLoad(true); setDates([])
        fetch(API.concat(`/rotations/search/${JSON.stringify({ from, to, date:moment.utc(date).add(-6,'day').format('yyyy-MM-DD')})}`), {method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const json = await response.json();
                    setDispoRotations(json)
                    const start = moment(date).add(moment().diff(moment(date), 'days')>-6 ? moment().diff(moment(date), 'days') : -6, 'days')
                    let list = []
                    for (let i = 0; i < 14; i++) {
                        const dt = start.add(i===0 ? 0 : 1, 'days').format('yyyy-MM-DD')
                        list.push(dt)
                    }
                    setDates(list)
                    setExpanded('panel1')
                }
                setLoad(false)
            }
        })
    }

    function get_total() {
        if (passagers.filter(psg=>psg.categorie!=='nourrisson').length<10&& passagers.length>0) {
            const select_rotation_tarifs = [...new Set(rotations.map(rt=>rt.tarifs??[]).flat())];
            const tfs = (select_rotation_tarifs.length>0 ? tarifs.filter(tf=>select_rotation_tarifs.includes(tf._id)) : tarifs)
            .filter(tf=>tf.isA_R===reservation.isA_R&&Boolean(tf.is_journee)===reservation.is_journee)
            
            const prix = passagers.reduce((tt, psg)=>tt+(tfs.find(tf=>psg.categorie?.toLowerCase().includes(tf.categorie))?.prix??0),0)
            const pmt = promos.find(prom=>prom.code===reservation.promo_code)
            const remise = pmt ?( pmt.is_in_percent? prix*(pmt?.reduction??0)/100 : pmt?.reduction??0) : 0
            return Number((prix-remise).toFixed(2))
        } else return 0
    }

    function submit() {
        setLoad(true); 
        const payed = reservation.payments?.reduce((total, current)=>total+current.montant,0)
        const user = JSON.parse(sessionStorage.getItem('agent'))
        const rsv = {...reservation,
            passagers:[...new Set(billets.map(bl=>bl.passager))],
            rotations: reservation.rotations.filter(rtn=>rtn!==''),
            date_depart: moment(reservation.date_depart).toDate(),
            date_retour: reservation.date_retour!==''? moment(reservation.date_retour).toDate(): null,
            total: price,
            rest_to_pay: price-payed,
            pay_status: payed<=0&&price>0 ?'en attente': payed<price ? 'Acompte versé':'payé',
            payments: payments?.filter(pay=>Number(pay.montant)>0).map(pay=>{pay.date=new Date(pay.date); pay.by=pay.by??user.where; pay.montant=Number(pay.montant); return pay})
        }
        if (billets.length===1) {
            const billet = billets[0]
            const info = {rotation:billet.rotation, passager:billet.passager, facture_update, montant:0}

            fetch(API.concat(`/billet/${billet?._id}/update`), {method: 'PATCH', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }, body: JSON.stringify(info)
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        // const {blt, rtn, rsv ,psg} = await response.json()
                        fetch(API.concat(`/reservation/${props.billet?.reservation?._id}`), {method:'PATCH', headers:{
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                            }, body: JSON.stringify(rsv)
                        }).then(async response=>{
                            const token = response.headers.get('x-auth-token')
                            if (response.status === 401) {
                                sessionStorage.clear()
                                updateConnect(false)
                            } else {
                                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                                }
                                if (response.status === 200) {
                                    const {rsv, rtn, blt, psg} = await response.json()
                                    Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                                        item.from = ports.find(p=> p._id===item?.from)
                                        item.to = ports.find(p=> p._id===item?.to)
                                        resolve(item)
                                    })})).then(rotations=>{
                                        Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                                            item.passager = psg.find(p=> p._id===item.passager)
                                            item.rotation = rotations.find(r=>r._id===item.rotation); 
                                            item.reservation = rsv
                                            resolve(item)
                                        })})).then(results=>{
                                            props.addRsv(results)
                                            props.close()
                                        })
                                    })
                                }else props.close()
                                setLoad(false)
                            }
                        })
                    }else setLoad(false)
                }
            })
        } else {
            fetch(API.concat(`/reservation/${props.billet?.reservation?._id}`), {method:'PATCH', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }, body: JSON.stringify(rsv)
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const {rsv, rtn, blt, psg} = await response.json()
                        Promise.all(rtn.map(item=>{return new Promise((resolve, reject) => {
                            item.from = ports.find(p=> p._id===item?.from)
                            item.to = ports.find(p=> p._id===item?.to)
                            resolve(item)
                        })})).then(rotations=>{
                            Promise.all(blt.map(item=>{return new Promise((resolve, reject) => {
                                item.passager = psg.find(p=> p._id===item.passager)
                                item.rotation = rotations.find(r=>r._id===item.rotation); 
                                item.reservation = rsv
                                resolve(item)
                            })})).then(results=>{
                                props.addRsv(results)
                                props.close()
                            })
                        })
                    }else props.close()
                    setLoad(false)
                }
            })    
        }
    }

    function handleSubmit() {
        const pmr = passagers.reduce((tt, psg)=>tt+(clients.find(cl=>cl._id===psg)?.isPmr?1:0),0)
        const no_pmr = rotations.filter(rtn=>rtn.dispo_pmr<pmr)
        if(no_pmr.length>0){
            setPmr(no_pmr)
        }
        else submit()
    }

    useEffect(()=>{
        //setdispo-rotation for set rotation for nopmr
        setLoad(true)
        fetch(API.concat(`/reservations/complet/${JSON.stringify({_id: props.billet?.reservation?._id??''})}`),{method:'GET', headers:{
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        }}).then(async response=>{
            const token = response.headers.get('x-auth-token')
            if (response.status === 401) {
                sessionStorage.clear()
                updateConnect(false)
            } else {
                if (token && token !== sessionStorage.getItem('token') && token !== '') {
                    sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                }
                if (response.status === 200) {
                    const {rsv, rtn, blt, psg} = await response.json();
                    const firstRtn = rtn.find(rt=>rt._id===rsv[0]?.rotations[0]??"")
                    setDispoRotations(rtn)
                    setPay(old=>rsv[0].payments??old);
                    setReservation({...rsv[0], date_depart: moment.utc(rsv[0].date_depart).format('yyyy-MM-DD'), date_retour: rsv[0].date_retour?moment.utc(rsv[0].date_retour).format('yyyy-MM-DD'):''})
                    setBillets(blt)
                    setPassagers(psg)
                    setfrom(firstRtn.from); setTo(firstRtn.to)
                    setpassengers(old=>psg?.length>0?old.map(cat=>({...cat, count:psg.filter(psg=>cat.label.toLowerCase().includes(psg.categorie)).length})):old)
                    setExpanded('panel3')
                }
                setLoad(false)
            }
        })
    }, [props.billet, props.passagers])

    useEffect(()=>{
        if(billets.length<=1){
            setLoad(true)
            fetch(API.concat('/passagers'), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }}).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        const webClients = Array.from(new Map(json.filter(cl => cl.isWeb).map(cl => [`${cl.nom.toLowerCase()}-${cl.prenom.toLowerCase()}-${cl.mail}`,{nom: cl.nom.toLowerCase(), prenom: cl.prenom.toLowerCase(), mail: cl.mail}])).values());
                        setclients([...json.filter(cl=>!cl.isWeb),
                        ...webClients.map(wcl=>json.find(cl=>cl.isWeb&&cl.nom.toLowerCase()===wcl.nom&&cl.prenom.toLowerCase()===wcl.prenom&&cl.mail===wcl.mail&&
                        !json.some(c=>c.isWeb&&c.nom.toLowerCase()===wcl.nom&&c.prenom.toLowerCase()===wcl.prenom&&c.mail===wcl.mail&&(moment.utc(c.identity_proof.exp_date).isAfter(moment.utc(cl.identity_proof.exp_date))||(c.tds_proof?.exp_date&&cl.tds_proof?.exp_date?moment.utc(c.tds_proof.exp_date).isAfter(moment.utc(cl.tds_proof.exp_date)):false)))))
                        ])
                    }
                  setLoad(false)
                }
            })
        }
    },[billets])

    useEffect(()=>{
        function getTarifs() {
            fetch(API.concat(`/tarifs`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setTarifs(json)
                    }
                }
            })
        }

        function getPromos() {
            fetch(API.concat(`/promos/${JSON.stringify({$and: [{start_date: {$lte: new Date(moment().format('yyyy-MM-DD'))}}, { end_date: {$gte: new Date(moment().format('yyyy-MM-DD'))}}]})}`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setpromos(json)
                    }
                }
            })
        }

        function getPorts() {
            fetch(API.concat(`/ports`), {method:'GET', headers:{
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                }
            }).then(async response=>{
                const token = response.headers.get('x-auth-token')
                if (response.status === 401) {
                    sessionStorage.clear()
                    updateConnect(false)
                } else {
                    if (token && token !== sessionStorage.getItem('token') && token !== '') {
                        sessionStorage.setItem('token', response.headers.get('x-auth-token'))
                    }
                    if (response.status === 200) {
                        const json = await response.json()
                        setports(json)
                    }
                    setLoad(false)
                }
            })
        }

        getTarifs(); getPromos(); getPorts()

    }, [updateConnect])

    useEffect(()=>{},[reservation])

    return(
        <Box sx={{display:'flex', flexDirection:'column', marginBottom:'30px'}}>
            <AppBar sx={{position:'relative'}}>
                <Toolbar sx={{display:'flex', flexDirection:'row'}}>
                    <Typography variant="h3" align="center" sx={{flex:1}}>Modifier la réservation {reservation.num}</Typography>
                    <IconButton onClick={props.close}><Close/></IconButton>
                </Toolbar>
            </AppBar>
            <Box display='flex' justifyContent='center' height='250px' sx={{backgroundColor:'transparent'}}>
                <Box display='flex' flexDirection='column' sx={{marginX:'20px', width:'80%', minWidth:'700px', alignSelf:'center'}}>
                    <Box display='flex' flexDirection='row' alignSelf='flex-start' alignItems='center' 
                    sx={{backgroundColor:'primary.main', overflow:'hidden', borderRadius:'6px 6px 0px 0px'}}>
                        <Button variant="contained" onClick={()=> setReservation(old=>{return({...old, isA_R:true})})} style={{backgroundColor:reservation.isA_R ? theme.palette.primary.light : theme.palette.primary.main, borderRadius:'6px 0px 0px 0px'}} >Aller/Retour</Button>
                        <Button variant="contained" onClick={()=> setReservation(old=>{return({...old, isA_R:false})})} sx={{backgroundColor: reservation.isA_R ? "primary.main" : "primary.light", borderRadius:'0px 6px 0px 0px'}}>Aller Simple</Button>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center', borderRadius:'0px 8px 8px 8px', backgroundColor:'primary.light', overflow:'hidden', height:'90px', width:'100%'}}>
                        <TextField value={from} onChange={(e)=>setfrom(e.target.value)} select fullWidth label='Départ' sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.sort((a,b)=>a.commune.toLowerCase()==='marin'?-1:b.commune.toLowerCase()==='marin'?1:a.commune<b.commune?-1:1).map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" variant="middle" sx={{height:'60%'}}/>
                        <TextField value={to??''} onChange={e=> setTo(e.target.value)} select fullWidth label='Arrivée' sx={{minWidth:'120px', "& fieldset": { border: 'none' }}}>
                            {ports.sort((a,b)=>a.commune.toLowerCase()==='marin'?-1:b.commune.toLowerCase()==='marin'?1:a.commune<b.commune?-1:1).map(port=>
                            <MenuItem key={port._id} value={port._id} style={{textTransform:'capitalize', whiteSpace:'pre-line'}}>
                                <Typography textTransform='capitalize'>
                                    {port.nom}<br/><span style={{textTransform:'uppercase', fontSize:10, color:'gray'}}>{port.pays}</span>
                                </Typography>
                            </MenuItem>)}
                        </TextField>
                        <Divider orientation="vertical" />
                        <TextField type="date" value={reservation.date_depart??''} onChange={(e)=> setReservation(old=>{return({...old, date_depart:e.target.value, date_retour:reservation.date_retour!==''&&new Date(e.target.value)>new Date(reservation.date_retour)?'':reservation.date_retour})})}
                        inputProps={{min: new Date().toISOString().split("T")[0]}}
                        fullWidth sx={{minWidth:'100px', "& fieldset": { border: 'none' }}} label='Aller' InputLabelProps={{shrink: true}}/>
                        {reservation.isA_R&&<Divider orientation="vertical" variant="middle" sx={{height:'60%'}}/>}
                        {reservation.isA_R&&<TextField type="date" value={reservation.date_retour??''} onChange={(e)=> setReservation(old=>{return({...old, date_retour:e.target.value, date_depart:reservation.date_depart!==''&&new Date(e.target.value)<new Date(reservation.date_depart)?'':reservation.date_depart})})}
                        inputProps={{min: new Date(reservation.date_depart).toISOString().split("T")[0]}}
                        fullWidth sx={{minWidth:'100px', "& fieldset": { border: 'none' }}} label='Retour' InputLabelProps={{shrink: true}}/>}
                        <Divider orientation="vertical" variant="middle"/>
                        <Button
                        style={{color:'black', textTransform:'none', whiteSpace:'pre-line'}}
                        sx={{minWidth:'120px', width:'100%', height:'100%', "& fieldset": { border: 'none' }}}
                        aria-controls={open ? 'Passagers' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        >
                            <Typography><span style={{fontWeight:'500'}}>Passagers</span><br/>
                            {cat_passengers.reduce((all, current)=>all+current.count,0)} passager(s)
                            </Typography>
                        </Button>
                        <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'Passagers',
                        }}
                        >
                            {cat_passengers.map(cat_passenger=>
                            <MenuItem key={cat_passenger.label}>
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label){
                                    p.count+=1
                                }return p }))}><Add/></IconButton>
                                {cat_passenger.count}
                                <IconButton onClick={()=>setpassengers(old=> old.map(p=> {if(p.label===cat_passenger.label&&p.count>0){
                                    p.count-=1
                                }return p }))}><Remove/></IconButton>
                                <span style={{textTransform:'capitalize'}}>{cat_passenger.label}</span>
                            </MenuItem>)}
                        </Menu>
                        <IconButton 
                        onClick={()=>searchRotations(reservation.date_depart, from, to)}
                        disabled={[from, to,reservation.date_depart].some(i=>i==='')||(reservation.isA_R&&reservation.date_retour==='')||cat_passengers.reduce((all, current)=>all+current.count,0)<=0||moment(reservation.date_depart).isBefore(moment())}
                        sx={{backgroundColor:'primary.main', width:'80px', borderRadius:0, height:'100%'}}>
                            <Search style={{fontSize:"32px", color:'white'}}/>
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' flexDirection='column' sx={{ minWidth:'700px', alignSelf:'center'}}>
                {dates.length>0&&<Accordion expanded={expanded === 'panel1'} onChange={(event, isExpanded)=>{if(isExpanded){searchRotations(reservation.date_depart, from, to)}; handleChange('panel1', isExpanded)}}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Traversée Aller
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RotatDays dates={dates} date={reservation.date_depart} setJournee={e=>setReservation(old=>{return({...old, is_journee:e})})}
                        setdate={(date)=>setReservation(old=>{return({...old, date_depart: date})})} a_r={reservation.isA_R} 
                        setrotation={(rotat)=>setReservation(old=>{return {...old, rotations:[rotat, reservation.rotations[1]??'']}})} 
                        passagers={cat_passengers} ports={ports} tarifs={tarifs} is_journee={reservation.is_journee} rotations={dispo_rotations} selected={reservation.rotations}
                        next={()=>{if(reservation.isA_R){searchRotations(reservation.date_retour, from, to)} else{fill_passagers()} handleChange(reservation.isA_R ?'panel2':'panel3', true)}}/>
                    </AccordionDetails>
                </Accordion>}
                {dates.length>0&&reservation.isA_R&&<Accordion expanded={expanded === 'panel2'} onChange={(event, isExpanded)=>{if(isExpanded){searchRotations(reservation.date_retour, from, to)}; handleChange('panel2', isExpanded)}} disabled={reservation.rotations.length<1}>
                    <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Traversée Retour
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RotatDays dates={dates} date={reservation.date_retour} a_r={reservation.isA_R} setJournee={e=>setReservation(old=>{return{...old, is_journee:e}})}
                        setdate={(date)=>setReservation(old=> {return{...old, date_retour: date}})} setrotation={(rotat)=>setReservation(old=>{return {...old, rotations:[reservation.rotations[0]??'', rotat]}})} 
                        passagers={cat_passengers} ports={ports} tarifs={tarifs} is_journee={reservation.is_journee} rotations={dispo_rotations} selected={reservation.rotations}
                        next={()=>{fill_passagers(); handleChange('panel3', true)}}/>
                    </AccordionDetails>
                </Accordion>}
                {billets.length<=1?billets.map(billet=><Box>
                    <Typography>Sélectionner un client déjà existant</Typography>
                    <ClientSearch clients={clients} value={billet.passager?? ''} setClient={psg=>{setBillets(old=>[{...old[0], passager:psg._id}]);setReservation(old=>({...old,passagers:[psg._id]}))}} style={{width:'80%', minWidth:'200px'}} />
                    <Divider orientation="horizontal" variant="middle" sx={{marginY:'30px'}}/>
                    <ClientForm key={billet.passager} edit={true} nopmr={dispo_rotations.find(rtn=>rtn._id===billet.rotation)?.dispo_pmr<=0} client={clients.find(cl=>cl._id===billet.passager)}
                    proofValidity={dispo_rotations.find(rtn=>rtn._id===billet.rotation)?.date_depart} setProofInvalid={setProofInvalid}
                    add={(edited)=> {setclients(old=> [...old.filter(cl=>cl._id!==edited._id), edited]); setBillets(old=>[{...old[0], passager:edited._id}]);setReservation(old=>({...old,passagers:[edited._id]}))}} close={()=> {}} />        
                    <Divider orientation="horizontal" variant="middle" sx={{marginY:'30px'}}/>
                </Box>)
                :<Accordion expanded={expanded === 'panel3'} onChange={(event, isExpanded)=> {fill_passagers(); handleChange('panel3', isExpanded)}}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>Billets</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {passagers.map((passager, index) => 
                        billets.filter(blt=>blt.passager===passager._id).length>0&&<Accordion key={index} expanded={nested_expanded === index} onChange={(event, isExpanded)=> handleChange(index, isExpanded, true)}>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
                                <Typography sx={{ width: '33%', flexShrink: 0, textTransform:'capitalize' }}>{passager!==''?(passager.nom+' '+passager.prenom):('Passager '+(index+1))}</Typography>
                            </AccordionSummary>
                            <AccordionDetails> 
                                {billets.filter(blt=>blt.passager===passager._id).map((billet, i)=>
                                    <BilletForm key={billet._id} {...{isAller:i===0,setProofInvalid,billet,passager,setPassagers,updates:reservation.updates,ports,
                                        rotation:dispo_rotations.find(rtn=>rtn._id===billet.rotation), close:()=>{},
                                        addRsv:(edited, rtn, rsv, psg)=> {setBillets(old=> [...old.filter(blt=>blt._id!==edited._id), edited]); 
                                            setDispoRotations(old=>[...old.filter(rt=>rt._id===rtn._id), rtn])
                                            setReservation({...rsv, date_depart: moment.utc(rsv.date_depart).format('yyyy-MM-DD'), date_retour: rsv.date_retour?moment.utc(rsv.date_retour).format('yyyy-MM-DD'):''})
                                            setPassagers(old=>[...old.filter(ps=>rsv.passagers.includes(ps._id)), psg])
                                            props.addRsv([{...edited,passager:psg,reservation:rsv,rotation:{...rtn,from:ports.find(pt=>pt._id===rtn.from),to:ports.find(pt=>pt._id===rtn.to)}}])
                                        }
                                    }}  />
                                )}
                            </AccordionDetails>
                        </Accordion>)}
                    </AccordionDetails>
                </Accordion>}
            </Box>

            {passagers.length>0&&!passagers.includes('')&&<Stack direction='row' spacing={5} sx={{alignItems:'center', margin:'30px', alignSelf:'center', width:'60%'}}>
                <TextField multiline rows={4} type="text" label="Commentaire" sx={{width:'60%', minWidth:'400px'}} value={reservation.commentaire??''} onChange={e=>setReservation(old=>{return{...old, commentaire:e.target.value}})}/>
                <TextField label="Code promo" value={reservation.promo_code??''} onChange={e=>setReservation(old=>{return{...old, promo_code:e.target.value}})} sx={{minWidth:'150px', width:'40%'}}/>
            </Stack>}

            <Box display='flex' flexDirection='column' component='form' onSubmit={e=>{e.preventDefault();handleSubmit()}}>
            <Stack direction='column' spacing={2} sx={{ minWidth:'700px', width:'60%', alignSelf:'center', marginTop:3}}>
                <Typography variant="subtitle1" marginX={3}>Informations de contact et de facturation</Typography>
                <TextField type="name" label="Nom complet" required fullWidth value={reservation.contact??''} onChange={e=>setReservation(old=>{return{...old, contact:e.target.value}})}/>
                <Stack direction='row' spacing={2} alignItems='center' marginX={3}>
                    <TextField type="email" label="Adresse e-mail de contact" placeholder="exemple@abc123.com" required fullWidth
                    value={reservation.mail??''} onChange={e=>setReservation(old=>{return{...old, mail:e.target.value}})} sx={{minWidth:'250px', margin:'20px', alignSelf:'center'}}/>
                    <TextField fullWidth sx={{minWidth:'200px'}} label="Tél" type="tel"
                    value={reservation.tel??''} onChange={(e)=>setReservation(old=>{return{...old, tel:e.target.value}})}/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="address" label="Adresse postale" fullWidth value={reservation.address1??''} onChange={e=>setReservation(old=>{return{...old, address1:e.target.value}})}/>
                    <TextField label="Complément d'adresse" fullWidth value={reservation.address2??''} onChange={e=>setReservation(old=>{return{...old, address2:e.target.value}})}/>
                </Stack>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <TextField type="zip-code" label="Code postale" required value={reservation.zipcode??''} onChange={e=>setReservation(old=>{return{...old, zipcode:e.target.value}})} sx={{width:'15%', minWidth:'120px'}}/>
                    <TextField type="city" label="Ville" required fullWidth value={reservation.city??''} onChange={e=>setReservation(old=>{return{...old, city:e.target.value}})}/>
                    <TextField label="Pays" required fullWidth value={reservation.country??''} onChange={e=>setReservation(old=>{return{...old, country:e.target.value}})}/>
                </Stack>
            </Stack >

            {rotations.length>0&&passagers.length>0&&!load&&<Box display='flex' flexDirection='column' alignItems='center' marginBlock={2}>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant="subtitle1">Total:</Typography>
                    <TextField type="number" size="small" value={price} sx={{width:'100px'}} onChange={e=>{if(!isNaN(Number(e.target.value)))setPrice(Number(e.target.value))}}/>
                </Stack>
                <Typography variant="subtitle1">Total payé: {payed.toFixed(2)}€</Typography> 
                <Typography variant="subtitle1">Reste à payer: {(price-payed).toFixed(2)}€</Typography> 
            </Box>}
            {props.billet?.reservation?.updates>1&&<Typography variant="subtitle1" style={{alignSelf:'end', margin:'0 20px 20px 0'}}>
                Frais de modification: {((props.billet?.reservation?.updates-1)*5).toFixed(2)}€
            </Typography>}
            {rotations.length>0&&passagers.length>0&&<><Typography variant="h4" align="center" marginY='20px'>Paiement</Typography>
            <PayForm payments={payments} setPay={setPay} passagers={clients.filter(clt=>passagers.includes(clt._id))} rest={price-payed}/></>}
            
            {props.billet?.reservation?.updates>0&&
            <FormControlLabel sx={{alignSelf:'end', marginTop:'10px'}} control={<Checkbox checked={facture_update} onChange={()=>setfacture(old=>!old)} color="primary" />} label="Facturer la modification" />}

            <Button type="submit" variant="contained" style={{alignSelf:'end', marginRight:'20px'}}
            disabled={passagers.length<=0||passagers.includes('')||proofInvalid||price<=0}>
                {load && <CircularProgress size={18} color='primary' style={{marginRight:7}}/> } valider la modification
            </Button>
            </Box>

            <Divider orientation="horizontal" variant="middle" sx={{marginY:'30px'}}/>
            <Typography variant="h6" align="center" marginBottom={2}>Documents</Typography>
            <Button onClick={downloadRsv}>Télécharger billets {price-payed<=0&&'et facture'}</Button>
            <Button onClick={sendRsvDoc}>Envoyer billets {price-payed<=0&&'et facture'}</Button>
            <Button onClick={printRsv}>Imprimer billets {price-payed<=0&&'et facture'}</Button>

            <Dialog open={no_pmr.length>0}>
                <DialogTitle>Il ne reste plus assez de place PMR sur {no_pmr.length>1?'les traversées':'la traversée'} {no_pmr.map(rtn=> ports.find(p=>p._id===rtn.from).nom+' -> '+ports.find(p=>p._id===rtn.to).nom).join(' et ')}</DialogTitle>
                <DialogContent>Souhaitez-vous mettre cette réservation sur liste d'attente?</DialogContent>
                <DialogActions>
                    <Button variant="clear" onClick={()=>{setPmr([])}}>Annuler</Button>
                    <Button variant="contained" onClick={()=>{setPmr([]); submit()}}>Mettre sur liste d'attente</Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={load}><CircularProgress/></Backdrop>
        </Box>
    )
}