import * as React from 'react';
import PropTypes from 'prop-types';
import {Box, Table, TableBody, TableCell, TableContainer, Stack ,Card ,CardContent ,Typography, TableHead, TablePagination ,useTheme, useMediaQuery, TableRow, TableSortLabel, Paper, Checkbox} from '@mui/material';

function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getComparator(order) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b)
    : (a, b) => -descendingComparator(a, b);
}

function stableSort(array, table, orderBy, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = orderBy >-1 ? comparator(table[orderBy]?.sortBy(a[0]), table[orderBy]?.sortBy(b[0])) : 0;
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// function EnhancedTableHead(props) {
//     const { order, orderBy, onRequestSort, table, rowCount, numSelected, onSelectAllClick } =    props;
    
//     const createSortHandler = (property) => (event) => onRequestSort(event, property)

//     return (
//         <TableHead>
//         <TableRow>
//           <TableCell padding="checkbox">
//             <Checkbox
//               color="primary"
//               indeterminate={numSelected > 0 && numSelected < rowCount}
//               checked={rowCount > 0 && numSelected === rowCount}
//               onChange={onSelectAllClick}
//               inputProps={{
//                 'aria-label': 'tout sélectionner',
//               }}
//             />
//           </TableCell>
//             {table.map((col, index) => (
//             <TableCell
//               key={index}
//               align='center'
//               sx={{fontWeight:'bold'}}
//               sortDirection={orderBy === index ? order : false}
//             >
//               {col.sortable? <TableSortLabel
//               active={orderBy === index}
//               direction={orderBy === index ? order : 'asc'}
//               onClick={createSortHandler(index)}
//               >
//                   {col.header}
//               </TableSortLabel> : col.header}
//             </TableCell>
//             ))}
//         </TableRow>
//         </TableHead>
//     );
// }

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, table, rowCount, numSelected, onSelectAllClick, isMobile } = props;
  const createSortHandler = (property) => (event) => onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'tout sélectionner',
            }}
          />
        </TableCell>
        {!isMobile && table.map((col, index) => (
          <TableCell
            key={index}
            align='center'
            sx={{fontWeight:'bold'}}
            sortDirection={orderBy === index ? order : false}
          >
            {col.sortable ? 
              <TableSortLabel
                active={orderBy === index}
                direction={orderBy === index ? order : 'asc'}
                onClick={createSortHandler(index)}
              >
                {col.header}
              </TableSortLabel> 
              : col.header
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.number.isRequired,
};

export default function SorTableCheckable(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(-1);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage||100);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAll = (event) => {
      if (event.target.checked) {
        props.setItems(props.data.map(item => item._id))
      } else {
        props.setItems([])
      }
    }

    const handleClick = (id) => {
      props.setItems(old =>{
        if (old.includes(id)) {
          return old.filter(item=> item !== id)
        } else {
          return [...old, id]
        }
      })
    }

    const visibleRows = React.useMemo(() => stableSort(props.data, props.table, orderBy, getComparator(order)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [props.data, props.table, order, orderBy, page, rowsPerPage]);

    return (isMobile?
      <Box sx={{ width: '100%', marginTop: '30px' }}>
        <Stack spacing={2}>
          {visibleRows.map((row) => (
            <Card key={row._id} sx={{ width: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {props.table[0].header}
                  </Typography>
                  <Checkbox
                    color="primary"
                    checked={props.selectItems.includes(row._id)}
                    onChange={() => handleClick(row._id)}
                  />
                </Box>
                <Stack spacing={1}>
                  {props.table.map((col, index) => {
                    // On extrait le contenu de la cellule sans le wrapper TableCell
                    const cellContent = col.row(row, index).props.children;
                    return (
                      <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="body2" color="text.secondary">
                          {col.header}:
                        </Typography>
                        <Typography variant="body2">
                          {cellContent}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage='lignes par page'
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`}
          component="div"
          count={props.data.length}
          rowsPerPage={100}
          page={page}
          onPageChange={(event, npage) => setPage(npage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        /> */}
      </Box>
    :<Box sx={{ width: '100%', marginTop:'30px' }}>
        <Paper sx={{ width: '100%', overflow:'hidden' }}>
          <TableContainer>
                <Table
                    sx={{ minWidth: 700 }}
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    table={props.table}
                    rowCount={props.data.length}
                    numSelected={props.selectItems.length}
                    onSelectAllClick={handleSelectAll}
                    />
                    <TableBody>
                    {visibleRows.map((row, index) =>
                        <TableRow
                        aria-checked={props.selectItems.includes(row._id)}
                        tabIndex={-1}
                        key={row._id}
                        selected={props.selectItems.includes(row._id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={props.selectItems.includes(row._id)}
                            onChange={e=>handleClick(row._id)}
                          />
                        </TableCell>
                          {props.table.map((col, index)=> col.row(row, index))} 
                        </TableRow>
                    )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={props.rowsPerPageOptions}
            labelRowsPerPage='lignes par page'
            labelDisplayedRows={({ from, to, count, page }) => { return `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`; }}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, npage)=> setPage(npage)}
            onRowsPerPageChange={(event)=> {setRowsPerPage(parseInt(event.target.value, 10)); setPage(0);}}
            /> 
        </Paper>
    </Box>
    );
}
